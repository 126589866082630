import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { authSelector } from 'store/auth/selector';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material';

import Button from 'components/Button';

export default function ProductCart() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { unsubmittedCarts } = useSelector(authSelector);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const onHandleShow = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();

    setAnchorEl(event.currentTarget);
  };

  const onHandleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();

    setAnchorEl(null);
  };

  const onAddProduct = () => {
    navigate('/products');
    setAnchorEl(null);
  };

  const onReviewCart = () => {
    navigate('/delivery_cart');
    setAnchorEl(null);
  };

  const onClickMenu = () => {
    unsubmittedCarts > 0 ? onReviewCart() : onAddProduct();
  };

  return (
    <NotificationContainer>
      <IconButton
        size="large"
        aria-label="notification"
        aria-controls="menu-appbar"
        color="inherit"
        className="notification"
        onClick={onHandleShow}>
        {unsubmittedCarts > 0 ? (
          <Badge variant="dot" color="error" className="badge-dot">
            <LocalShippingIcon />
          </Badge>
        ) : (
          <LocalShippingIcon />
        )}
      </IconButton>
      <MenuContainer
        id="notification-menu"
        key={unsubmittedCarts}
        anchorEl={anchorEl}
        open={open}
        onClose={onHandleClose}
        onClick={onClickMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <MenuItemContainer
          key={'item' + unsubmittedCarts}
          sx={{
            flexDirection: 'column',
            padding: '48px 16px',
            alignItems: 'center',
            justifyContent: 'center',
            rowGap: '12px',
            cursor: 'default'
          }}>
          <span>
            {unsubmittedCarts > 0
              ? `You have ${unsubmittedCarts} item${
                  unsubmittedCarts > 1 ? 's' : ''
                } waiting for delivery`
              : 'Your fulfillment batch is empty'}
          </span>
          {unsubmittedCarts > 0 ? (
            <Button
              key="add-button"
              text={'Review & Finalize Batch'}
              styles={{
                minWidth: '226px',
                border: `1px solid ${theme.colors.primary.main}`,
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)'
              }}
              onClick={onReviewCart}
            />
          ) : (
            <Button
              key="add-button"
              text={'Add products'}
              styles={{
                minWidth: '136px',
                border: `1px solid ${theme.colors.primary.main}`,
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)'
              }}
              variant="text"
              onClick={onAddProduct}
            />
          )}
        </MenuItemContainer>
      </MenuContainer>
    </NotificationContainer>
  );
}

const NotificationContainer = styled(Box)<{ hasNotifications?: boolean }>`
  display: flex !important;

  .notification {
    cursor: pointer !important;
    padding: 0px;
    padding-left: 12px;

    ${({ theme }) => theme.breakpoints.up('md')} {
      padding: 12px;
    }
  }
`;

const MenuContainer = styled(Menu)`
  .MuiList-root {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
`;

const MenuItemContainer = styled(MenuItem)`
  display: flex;
  flex-direction: row;
  gap: 8px;

  width: 360px;
  padding-top: 20px;
  padding-bottom: 16px;
  padding-right: 20px;
  white-space: initial;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray.normal};
  }

  button.add-button {
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary.dark};
    }
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 480px;
  }
`;
