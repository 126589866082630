import * as React from 'react';
import { Wrapper, StyledAppBar, StyledSearchInput } from './styles';

import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { ArrowDropDown } from '@mui/icons-material';

import SearchInput from 'components/SearchInput';
import AppBarSearch from 'components/AppBar/Search';
import AppBarMobileMenu from 'components/AppBar/MobileMenu';
import AppBarDesktopMenu from 'components/AppBar/DesktopMenu';
import AppBarUserInfo, { UserProps } from 'components/AppBar/UserInfo';

import { Company } from 'store/auth/interface';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from 'store/auth/selector';
import { setGlobalSearch } from 'store/auth/reducer';
import { setSorting } from 'store/data/reducer';

type AppBarProps = {
  navbar: any;
  sidebar: any;
  isSupplier: boolean;
  companyName?: string;
  user: UserProps;
  searchPlaceholder?: string;
  companies?: Company[];
  onSearch?: () => void;
  onClear?: () => void;
  withSearch?: boolean;
  textSearch?: string;
};

export default function AppBar(props: AppBarProps) {
  const {
    navbar,
    sidebar,
    isSupplier,
    user,
    searchPlaceholder,
    onSearch = () => {},
    onClear = () => {},
    companies = [],
    withSearch = true,
    textSearch = ''
  } = props;
  const dispatch = useDispatch();
  const [search, setSearch] = React.useState<any>(textSearch || '');

  const { globalSearch } = useSelector(authSelector);

  const onSearchInput = (value: string) => {
    dispatch(setSorting(false));
    setSearch(value);
  };

  React.useEffect(() => {
    dispatch(setGlobalSearch(search));
  }, [search]);

  return (
    <Box sx={{ height: 'auto', width: '100%' }}>
      <StyledAppBar position="fixed">
        <Toolbar disableGutters>
          <Wrapper id="DesktopAppBarContainer" className="DesktopAppBarContainer">
            <AppBarDesktopMenu pages={navbar} menu={sidebar} />
          </Wrapper>
          <Wrapper id="MobileAppBarContainer" className="MobileAppBarContainer">
            <AppBarMobileMenu menu={sidebar} />
          </Wrapper>
          <Wrapper sx={{ flexGrow: '1', justifyContent: 'flex-end' }}>
            {!isSupplier && (
              <StyledSearchInput id="SearchInputFilter">
                <SearchInput
                  select
                  dropdown
                  key="search-company"
                  placeholder="Select suppliers..."
                  icon={<ArrowDropDown />}
                  width={220}
                  showCount
                  hideCaret={true}
                  onClick={onSearch}
                  selected={companies.map((item: any) => ({ ...item, label: item.name }))}
                  onSelectClear={onClear}
                />
              </StyledSearchInput>
            )}
            {withSearch && (
              <AppBarSearch
                placeholder={searchPlaceholder}
                value={globalSearch || search || ''}
                onChange={onSearchInput}
              />
            )}
            <AppBarUserInfo name={user.name} group={user.group} />
          </Wrapper>
        </Toolbar>
      </StyledAppBar>
    </Box>
  );
}
